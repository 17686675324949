.custom-calendar {
    & > .rbc-calendar {
        min-height: 500px;
    }
    & .rbc-month-view {
        & .rbc-event {
            background-color: $secondary-color;
            outline: none;
            &:hover {
                opacity: 0.8;
            }
        }
        & .rbc-selected-cell {
            background-color: white;
        }
        & .rbc-today.rbc-selected-cell {
            background-color: #eaf6ff;
        }
        & .rbc-off-range-bg.rbc-selected-cell {
            background: #e6e6e6;
        }
    }
}

.rbc-overlay {
    & .rbc-event {
        background-color: $secondary-color;
        &:hover {
            opacity: 0.8;
        }
    }
    & .rbc-event.rbc-selected {
        background-color: $secondary-color;
        opacity: 0.8;
    }
}
