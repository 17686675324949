.main-editor {
    height: 180px !important;
    border: 1px solid $gray-60;
    padding: 10px;
    border-radius: 0.25rem;
    ol {
        display: block;
    }
}
.editor-is-invalid {
    border: 1px solid $danger-color;
}
