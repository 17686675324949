.pagePrint {
  visibility: visible;
}

.pagePrintContainer {
  width: 21cm;
  min-height: 29.7cm;
  margin: 20px auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 17px;
}

.pagePrintHeader {
  padding: 1cm 2cm 0;
  font-size: 17px;

  p {
    font-size: 17px;
  }
}

.pagePrintBody {
  padding: 1cm 2cm 1.9cm;
  font-size: 14px;

  p {
    font-size: 14px;
  }
}

@media print {
  body * {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper.close_icon ~ .page-body {
    margin-left: 0;
  }

  .page-header .close_icon,
  .page-header,
  .sidebar-wrapper .close_icon,
  .sidebar-wrapper,
  .footer {
    display: none;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  .d-print-none {
    display: none !important;
  }

  .pt {
    padding-top: 0 !important;
  }

  .pl-3,
  .px-3 {
    padding-left: 0 !important;
  }

  .pr-3,
  .px-3 {
    padding-right: 0 !important;
  }

  .overflow-auto {
    overflow: hidden !important;
  }

  .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
    margin: 0;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  .pagePrint {
    visibility: visible;
  }

  .pagePrintContainer {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    border: initial;
    border-radius: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    flex: none;
    grid: none;
  }

  .pagePrintHeader {
    font-size: 20px;

    p {
      font-size: 20px;
    }
  }

  .pagePrintBody {
    font-size: 20px;

    p {
      font-size: 20px;
    }
  }
}

@page {
  size: A4;
  margin: 0;
}
