/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

// *** utils ***

@import 'utils/variables';

:root {
    --theme-deafult: #7366ff;
    --theme-secondary: #f73164;
}

// *** base ***
@import 'base/reset';
@import 'base/typography';

//  *** components ***
@import 'components/according';
@import 'components/alert';
@import 'components/avatars';
@import 'components/badge';
@import 'components/bookmark';
@import 'components/breadcrumb';
@import 'components/builders';

@import 'components/buttons';

@import 'components/card';
@import 'components/color';
@import 'components/datepicker';
@import 'components/dropdown';
//@import "components/datatable";
// @import "components/form-builder";
@import 'components/form-input';
@import 'components/form-wizard';
@import 'components/forms';
// @import "components/form_builder-2";
//@import "components/icons";
@import 'components/list';
@import 'components/loader';
@import 'components/popover';
@import 'components/print';
@import 'components/radio';
@import 'components/ribbon';
@import 'components/switch';
@import 'components/tab';
@import 'components/table';
@import 'components/touchspin';
@import 'components/react-draft-wysiwyg';
@import 'components/react-big-calendar';
@import 'components/carousel';
@import 'components/chart-js';
//@import "components/tour";
//@import "components/typeahead-search";
//@import "components/react-plugin";
//@import "components/slick.scss";
//@import "components/slick-theme.scss";

//	*** pages ***

//@import "pages/blog";
//@import "pages/bookmark-app";
//@import "pages/cart";
//@import "pages/chart";
//@import "pages/chat";
//@import "pages/checkout";
//@import "pages/comingsoon";
@import 'pages/contacts';
// @import "pages/calendar";
//@import "pages/dashboard_2";
//@import "pages/dashboard_default";
//@import "pages/ecommerce";
//@import "pages/email-application";
//@import "pages/errorpage";
//@import "pages/faq";
//@import "pages/file";
//@import "pages/gallery";
//@import "pages/job-search";
//@import "pages/kanban";
//@import "pages/knowledgebase";
//@import "pages/language";
//@import "pages/learning";
//@import "pages/login";
@import 'pages/megaoption';
//@import "pages/order-history";
//@import "pages/page";
@import 'pages/pricing';
//@import "pages/progress";
//@import "pages/projectlist";
//@import "pages/social-app";
//@import "pages/task";
//@import "pages/timeline-v";
//@import "pages/timeliny";
//@import "pages/user-profile";
//@import "pages/wishlist";

// *** layout ***

@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/search';
@import 'layout/sidebar';
@import 'layout/modal';
//@import "layout/rtl";
//@import "layout/box-layout";
@import 'pagePrint';

/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark";
@import "themes/dark-sidebar";
@import "themes/theme-customizer";
@import 'themes/responsive';

body {
    background-color: aliceblue;
}

body.dark-only {
    background-color: #262f37;
}

.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
    background-color: aliceblue;
}

.dark-only .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .page-body {
    background-color: #262f37;
}

.pre-fonts {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

@media (min-width: 576px) {
    .modal-sm {
        max-width: 340px;
    }
}
