@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/material-design-icon.scss";
@import "vendors/typicon-icon.scss";
@import "vendors/pe7-icon.scss";
@import "vendors/ionic-icon.scss";
@import "vendors/simple-line-icon.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

// ReactToastify
@import "~react-toastify/dist/ReactToastify.css";



