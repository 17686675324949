.reactour__helper {
  padding: 0 !important;
  width: 330px !important;
}

.reactour__helper div[data-tour-elem=controls] {
  margin: 10px 0 10px !important;
  padding: 0 16px !important;
  justify-content: end !important;
}

.reactour__helper div[data-tour-elem=controls] button {
  margin: 0 !important;
}

.reactour__helper div[data-tour-elem=controls] button:first-child {
  margin-right: 4px !important;
}

.reactour__helper span[data-tour-elem=badge] {
  background-color: transparent !important;
  color: #7a7a7a !important;
  box-shadow: none !important;
  top: unset !important;
  bottom: 14px !important;
  left: 16px !important;
  font-family: inherit !important;
  font-size: 12px !important;
  padding: 0 !important;
}