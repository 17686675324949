.custom-carousel  {
    & > .carousel-indicators{
        z-index: 3;
        justify-content: end;
        margin: 0;

        & > li {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ffffff;
            opacity: 1;

            &.active {
                background-color: #000000;
            }
        }
    }
}
