.calendarContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 3 months in a row for responsiveness */
  grid-gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  justify-items: center;
}

.calendar {
  width: fit-content;
  text-align: center;
  display: inline-block;
}

.header {
  color: #000;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.header .monthYear {
  font-size: 12px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  padding: 5px;
  font-size: 10px;
}

.days div {
  padding: 5px;
  color: black;
}

.days div:nth-child(7n) {
  color: #DC3545;
}

.days div.today {
  background: #009688;
  color: #fff;
  border-radius: 50%;
}

.days div:not(.today):hover {
  background: #009688;
  color: #fff;
  cursor: pointer;
}

table.listHolidays {
  margin: 12px 10px 0;
}

table.listHolidays td {
  font-size: 10px;
  color: #000;
  text-align: left;
}
